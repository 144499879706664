.page {
    background-color: #121212;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}

.page::-webkit-scrollbar {
    width: 0em;
}

.page::-webkit-scrollbar-track {}

.page::-webkit-scrollbar-thumb {
    background-color: var(--default-primary);
    border-radius: 9999px;
}

.spacer-top {
    flex: 2;
    background-color: #121212;

}

.spacer-bottom {
    flex: 2;
    background-color: #121212;
}